import React from "react";

import MapApp from './MapApp';
import ApolloProvider from './ApolloProvider';
import ContentTypeDefinitionsProvider from "./ContentTypeDefinitionProvider";

const Root = () => {

  return (
    <ApolloProvider>
      <ContentTypeDefinitionsProvider>
        <MapApp />
      </ContentTypeDefinitionsProvider>
    </ApolloProvider>
  )
}

export default Root
