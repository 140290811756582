import { camelCase } from 'lodash'

import {
  GRAPHQL_TYPENAME_EZDATETIME,
  GRAPHQL_TYPENAME_EZGMAPLOCATION,
  GRAPHQL_TYPENAME_EZIMAGE,
} from './constants'

/**
 * Returns the plural of a content type identifer. This function is needed to handle exceptions
 * for strings ending with -s or -ion.
 * Note: Ibexa has some strange plural building rules like obstruction, which becomes obstructia.
 * @param {string} identifier The content type identifier to get the plural for
 * @returns
 */
export const buildPlural = (identifier) => {
  const camelCaseIdentifier = camelCase(identifier)
  if (camelCaseIdentifier.endsWith('s')) {
    return camelCaseIdentifier + 'es'
  }
  if (camelCaseIdentifier.endsWith('ion')) {
    return camelCaseIdentifier.slice(0, -3) + 'ia'
  }
  return camelCaseIdentifier + 's'
}

/**
 * In an Ibexa GraphQL response, there are two levels of hierarchy before the actual data.
 * This helper returns the data.
 *
 * @param {object} response A GraphQL response
 * @param {string} contentTypeGroup The content type group where the content type is defined in
 * @param {string} contentTypeIdentifier The content type identifier
 * @param {boolean} isQueryingMultipleElements Wether the request fetches a single or muliple elements
 * @returns {object|array} Either a single content object or an array of content nodes
 */
export const getContentDataFromResponse = (response, contentTypeGroup, contentTypeIdentifier, isQueryingMultipleElements = false) => {
  const content = response
    ?.[camelCase(contentTypeGroup)]
    ?.[isQueryingMultipleElements ? buildPlural(contentTypeIdentifier) : camelCase(contentTypeIdentifier)]
  return isQueryingMultipleElements ? content?.edges : content
}

/**
 * Returns the field type as returned from Ibexa GraphQL.
 * It is determined from property __typename, which is added automatically
 * to all field value objects (i.e. all field values except ezstring and eztext, which are returned as string)
 *
 * @param {object|string} value The value of the field. Either a string (ezstring, eztext) or object (ezimage, ezdatetime, ezgmaplocation)
 * @returns {string|undefined} Returns either the field type as string or undefined, if the field type is plain text
 */
export const getGraphqlFieldTypename = (value) => {
  return value?.__typename
}

/**
 * Checks wether a field contains any data.
 *
 * @param {object|string} value The value of the field. Either a string (ezstring, eztext) or object (ezimage, ezdatetime, ezgmaplocation)
 * @returns {boolean} Wether this field, depending on its type, contains data
 */
export const isFieldValueEmpty = (value) => {
  switch (getGraphqlFieldTypename(value)) {
  case GRAPHQL_TYPENAME_EZDATETIME:
    return !value.timestamp
  case GRAPHQL_TYPENAME_EZGMAPLOCATION:
    return !value.text
  case GRAPHQL_TYPENAME_EZIMAGE:
    return !value.uri
  // ezstring and eztext
  default:
    return !value?.length
  }
}
