import React, {useContext, useMemo, useCallback, useState, useEffect, useRef} from 'react';
import {Layer, Map as MapGl, Source} from 'react-map-gl';
import { camelCase } from 'lodash';
import { feature, featureCollection } from '@turf/helpers';
import { gql, useLazyQuery } from '@apollo/client';

import { ContentTypeDefinitionsContext } from './ContentTypeDefinitionProvider';
import { queryReportsPoiData } from "./ibexa-graphql/queries";
import MarkerPopup from "./MarkerPopup";

import ngeohash from 'ngeohash';

const MapApp = () => {
  const [activeMarkerFeature, setActiveMarkerFeature] = useState(null);
  const [activeMarkerContentType, setActiveMarkerContentType] = useState(undefined);
  const [activeMarkerContentId, setActiveMarkerContentId] = useState(undefined);

  const mapRef = useRef();

  const [mapBounds, setMapBounds] = useState(() => {
    const boundsObj = mapRef?.current?.getBounds()
    return boundsObj
  })

  useEffect(() => {
    const updateMapBounds = () => {
      const boundsObj = mapRef?.current?.getBounds()
      if (typeof boundsObj !== 'undefined') {
        setMapBounds(boundsObj)
      }
    }
    mapRef?.current?.on('moveend', updateMapBounds)
    mapRef?.current?.on('load', updateMapBounds)

    return () => {
      mapRef?.current?.off('moveend', updateMapBounds)
      mapRef?.current?.off('load', updateMapBounds)
    }
  }, [mapRef?.current])

  // based on the request / query logic copy/pasted from the app
  const { contentTypeDefinitions } = useContext(ContentTypeDefinitionsContext);
  // Get all report categories
  const messageContentTypes = useMemo(() => {
    return contentTypeDefinitions?.map(contentTypeDefinition => {
      return camelCase(contentTypeDefinition?._info?.identifier)
    }) || null
  }, [contentTypeDefinitions])

  const [refetch, { data: reportsPoiData }] = useLazyQuery(
    gql(queryReportsPoiData(messageContentTypes)), {
    }
  );

  const geoHashes = useMemo(() => {
    if (
      typeof mapBounds?._sw.lat !== 'undefined' &&
      typeof mapBounds._sw.lng !== 'undefined' &&
      typeof mapBounds._ne.lat !== 'undefined' &&
      typeof mapBounds._ne.lng !== 'undefined'
    ) {
      const bboxesResult = ngeohash.bboxes(
        mapBounds._sw.lat,
        mapBounds._sw.lng,
        mapBounds._ne.lat,
        mapBounds._ne.lng,
        5
      )
      return bboxesResult
    }
  }, [
    mapBounds?._ne.lat,
    mapBounds?._ne.lng,
    mapBounds?._sw.lat,
    mapBounds?._sw.lng,
  ])

  useEffect(() => {
    if (geoHashes) {
      refetch({ variables: { geoHashes }})
    }
  }, [refetch, geoHashes])

  const poisFeatureCollection = useMemo(() => {
    if (!reportsPoiData?.meldungskategorien) {
      return featureCollection([]);
    }

    // go through categories and build features from report data
    const features = Object.keys(reportsPoiData.meldungskategorien)
      .filter(key => !key.startsWith('_')) // filter out graphql metadata
      .map(category => {
        return reportsPoiData.meldungskategorien[category].edges.map(edge => {
          // Build and return feature
          return feature({
            type: 'Point',
            coordinates: [
              parseFloat(edge.node.location.longitude), // comes as string from query, so parse
              parseFloat(edge.node.location.latitude)]
          },
            {
              contentId: edge.node?._info?.id,
              contentType: edge.node?._type?.identifier,
              markerIcon: `marker_report_${edge.node?.workflow_stage}`,
              workflowStage: edge.node?.workflow_stage
            }
          )
        })
      })
      .flat()
      .filter(feature => {
        return !['archived','start', 'done', null].includes(feature.properties?.workflowStage)
      }) || []

    return featureCollection(features);
  }, [reportsPoiData]);

  const onPoiClick = useCallback(event => {
    const {
      features,
    } = event;

    const { contentType, contentId } = features?.[0]?.properties || {}

    // catches cases when infowindow is closed with other map interaction
    if (((activeMarkerContentType && activeMarkerContentType !== contentType) && (activeMarkerContentId && activeMarkerContentId !== contentId))) {
      handleCloseActiveMarker()
    } else if (typeof contentId !== 'undefined' && typeof contentType !== 'undefined') {
      setActiveMarkerContentType(contentType)
      setActiveMarkerContentId(contentId)
      setActiveMarkerFeature(features?.[0])
    }

  }, [activeMarkerContentId, activeMarkerContentType]);

  const handleCloseActiveMarker = () => {
    setActiveMarkerContentType(null)
    setActiveMarkerContentId(null)
    setActiveMarkerFeature(null)
  }

  const hasActiveMarker = useMemo(() => {
    return activeMarkerContentId && activeMarkerContentType
  }, [activeMarkerContentId, activeMarkerContentType])

  const layerStyle = {
    'type': 'symbol',
    'layout': {
      'icon-image': [
        'coalesce',
        ['image', ['get', 'markerIcon']],
        ['image', 'marker_report_start']
      ],
      'icon-size': 1,
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
    }
  }

  const layerStyleCluster = {
    'type': 'circle',
    'paint': {
      'circle-color': '#ffffff',
      'circle-radius': [
        'step',
        ['get', 'point_count'],
        //size
        20,
        // number in cluster
        50,
        //size
        30,
        // number
        250,
        40,
        500,
        60
      ],
      'circle-stroke-color': '#000000',
      'circle-stroke-width': 2
    }}

    const layerStyleClusterCount = {
      type: 'symbol',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-size': 12,
      },
      paint: {
        'text-color': '#000000'
      }
    }

  // apply click handler for cluster interaction
  useEffect(() => {
    mapRef?.current?.on('click', 'reportLayer_cluster', (e) => {
      const features = mapRef?.current.queryRenderedFeatures(e.point, {
        layers: ['reportLayer_cluster']
      })

      const clusterId = features[0].properties.cluster_id
      mapRef?.current.getSource('kaf').getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (err) return

          mapRef?.current.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          })
        }
      )
    })
    return () => mapRef?.current?.off('click', 'reportLayer_cluster')
  }, [mapRef?.current]);

  return (
    <MapGl
      ref={mapRef}
      initialViewState={{
        longitude: 8.403920580287156,
        latitude: 49.00925532078508,
        zoom: 14,
      }}
      onClick={onPoiClick}
      scrollZoom
      cooperativeGestures
      dragPan
      dragRotate={false}
      pitchWithRotate={false}
      touchZoomRotate
      touchPitch={false}
      language={'de'}
      locale={{
        'ScrollZoomBlocker.CtrlMessage': 'Drücken Sie ctrl + scroll um auf der Karte zu zoomen',
        'ScrollZoomBlocker.CmdMessage': 'Drücken Sie ⌘ + scroll um auf der Karte zu zoomen',
        'TouchPanBlocker.Message': 'Nutzen Sie zwei Finger um die Karte zu bewegen'
      }}
      interactiveLayerIds={['reportLayer']}
      // TODO Make configurable from env
      mapStyle="mapbox://styles/raumobil/cl0532kd8000g14moc5f5m67x"
      mapboxAccessToken={'pk.eyJ1IjoicmF1bW9iaWwiLCJhIjoiY2o3YWppMjR3MGRjNzJ3cDdnZHFxbXRjOSJ9.6Ybvb8Qmb1e51d8l0cqFEg'}
      onLoad={(e) => {
        const map = e.target
        map.touchZoomRotate.disableRotation()
      }}
      minZoom={12}
    >
        <Source
          id={'kaf'}
          type={'geojson'}
          data={poisFeatureCollection}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
          clusterMinPoints={4}
        >

          <Layer
            id={'reportLayer_cluster'}
            filter={['has', 'point_count']}
            {...layerStyleCluster}
          />
          <Layer
            id={'reportLayer_cluster_count'}
            filter={['has', 'point_count']}
            {...layerStyleClusterCount}
          />
           <Layer
            id={'reportLayer'}
            {...layerStyle}
          />
          {hasActiveMarker &&
            <MarkerPopup
              contentId={activeMarkerContentId}
              contentType={activeMarkerContentType}
              feature={activeMarkerFeature}
              closeButton
              onClose={handleCloseActiveMarker}
            />
          }
      </Source>
    </MapGl>
  )
}


export default MapApp


