import React, { useMemo } from 'react';
import {Popup} from "react-map-gl";
import {gql, useQuery} from "@apollo/client";
import {contentTypeFieldsQuery, queryReportDetails} from "./ibexa-graphql/queries";
import {getContentDataFromResponse} from "./util/graphql";
import {PUBLIC_REPORT_TYPES_GROUP_NAME} from "./util/constants";

const MarkerPopup = ({
  contentId,
  contentType,
  feature,
  onClose
 }) => {
    // query field definitions for this content type
  const { data: { _repository: { contentType: { fieldDefinitions } = {} } = {} } = {}, loading: contentTypeFieldsQueryLoading } = useQuery(
      gql(contentTypeFieldsQuery), {
        skip: !contentType,
        variables: {
          contentTypeIdentifier: contentType,
        },
      }
    )
  // query report details using field definitions requested previously
  const { data: reportDetailsResponse, loading: queryReportDetailsLoading } = useQuery(
    gql(queryReportDetails(contentType, contentId, fieldDefinitions)),
    { skip: !contentId || !contentType || !fieldDefinitions }
  )

  const reportDetails = useMemo(() => {
    return getContentDataFromResponse(reportDetailsResponse, PUBLIC_REPORT_TYPES_GROUP_NAME, contentType) ?? null
  }, [contentType, reportDetailsResponse])

  const i18nMappings = {
    categories: {
      pollution: "Verunreinigungen",
      hazardSpot: "Gefahrenstellen",
      trafficLights: "Ampeln",
      traffic: "Verkehrsverstöße / Verkehrsführung",
      streetLights: "Straßenbeleuchtung",
      eScooter: "E-Scooter"
    },
  state: {
      start: "Start",
      arrived: "Eingetroffen",
      inProcess: "in Bearbeitung",
      delayed: "Zurückgestellt",
      done: "Erledigt",
      wontFix: "Wurde weitergeleitet",
      archived: "Archiviert"
  }};


  return (
      <Popup
        longitude={feature?.geometry?.coordinates[0]}
        latitude={feature?.geometry?.coordinates[1]}
        anchor="bottom"
        onClose={onClose}
      >
        <div style={{minWidth: '150px', margin: '15px', fontSize: 16}}>
          Kategorie:<br />
          <p style={{fontWeight: 'bold'}}>
            {eval(`i18nMappings.categories.${reportDetails?._type.identifier.split('_')[0]}`)}
          </p>
          <p className={`state state-${reportDetails?.workflow_stage}`}>{eval(`i18nMappings.state.${reportDetails?.workflow_stage}`)}  </p>
          <p>
            {/* TODO replace with correct link */}
            <a href={`/view/content/${contentId}`} className={'infoLink'} >mehr Informationen</a>
          </p>
        </div>
      </Popup>
  )
}

export default MarkerPopup
