import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';

import {
  PUBLIC_REPORT_TYPES_GROUP_ID,
  PUBLIC_REPORT_TYPES_GROUP_NAME,
} from './util/constants';

import {
  queryContentTypesByGroup,
  queryMultipleFieldDefinitions,
} from './ibexa-graphql/queries';

export const ContentTypeDefinitionsContext = createContext({});
ContentTypeDefinitionsContext.displayName = 'ContentTypeDefinitionsContext';

/**
 * Returns a provider which fetches all content type definitions for use within the app
 * @param children
 */
const ContentTypeDefinitionsProvider = ({
  children,
}) => {
  // fetch list of content types and field definitions first
  const { data: contentTypeListData } = useQuery(gql(queryContentTypesByGroup(PUBLIC_REPORT_TYPES_GROUP_ID)));

  const contentTypeList = useMemo(() => {
    return contentTypeListData?._repository.contentTypes || null
  }, [contentTypeListData]);

  const fieldDefinitionsQuery = useMemo(() => {
    if (contentTypeList) {
      return gql(queryMultipleFieldDefinitions(PUBLIC_REPORT_TYPES_GROUP_NAME, contentTypeList))
    } else {
      return gql(queryContentTypesByGroup(PUBLIC_REPORT_TYPES_GROUP_ID))
    }
  }, [contentTypeList]);

  // fetch field defintion details afterwards
  const { data: contentTypeDefinitionsData } = useQuery(
    fieldDefinitionsQuery,
    { skip: !contentTypeList },
  );

  const contentTypeDefinitions = useMemo(() => contentTypeDefinitionsData
      ? Object.entries(
          contentTypeDefinitionsData?.meldungskategorien?._types)
        // graphql query result contains debug information with keys starting with '__'
        ?.filter(([key]) => !key.startsWith('__'))
        ?.map(([, value]) => ({ ...value }))
      : null
    , [contentTypeDefinitionsData]);

  return (
    <ContentTypeDefinitionsContext.Provider value={{
      contentTypeDefinitions,
    }}>
      {children}
    </ContentTypeDefinitionsContext.Provider>
  )
}

ContentTypeDefinitionsProvider.propTypes = {
  /** React children */
  children: PropTypes.node,
}

export default ContentTypeDefinitionsProvider
