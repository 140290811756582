// This group id correpsonds to content type group 'Meldungskategorien'
export const PUBLIC_REPORT_TYPES_GROUP_ID = '4'
export const PUBLIC_REPORT_TYPES_GROUP_NAME = 'Meldungskategorien'
export const PUBLIC_COMMUNICATION_TYPES_GROUP_NAME = 'Kommunikation'

/*
 * Ibexa field types as returned from GraphQL responses
 * When dealing with other values than text, Ibexa GraphQL adds '__typename' to the corresponding object.
 * As text is returned as a simple value, there is no distinction between ezstring and eztext fields.
 */
export const GRAPHQL_TYPENAME_EZDATETIME = 'DateTime'
export const GRAPHQL_TYPENAME_EZGMAPLOCATION = 'MapLocationFieldValue'
export const GRAPHQL_TYPENAME_EZIMAGE = 'ImageFieldValue'

// Image variations as defined in ezplatform.yaml
export const IMAGE_VARIATION_APP_REPORT_DETAIL = 'app_report_detail'

// Field identifiers not to be requested for content type forms
export const BLACKLIST_FIELD_TYPE_QUERY_IDENTIFIERS = [
  'case_number', // this field is set automatically by Ibexa and should not appear in a content type form
]
