import {camelCase} from "lodash";
import {
  BLACKLIST_FIELD_TYPE_QUERY_IDENTIFIERS,
  IMAGE_VARIATION_APP_REPORT_DETAIL,
  PUBLIC_REPORT_TYPES_GROUP_NAME
} from "../util/constants";

import {buildPlural} from '../util/graphql'

/**
 * Generates a GraphQL query for requesting a single content by content type and content id. It also contains
 * information about the content type (_type) and metadata about the content itself (_info).
 *
 * @param {string} contentTypeIdentifier Represents the subcategory of the report
 * @param {number} id The content id
 * @param {array} fields The fields to be displayed
 * @returns {string} A GraphQL query which can be used to retrieve a single report
 */
export const queryReportDetails = (contentTypeIdentifier, id, fields) => {
  return `{
    ${camelCase(PUBLIC_REPORT_TYPES_GROUP_NAME)} {
      ${camelCase(contentTypeIdentifier)} (id: ${id}) {
        _info {
          mainLocationId
          publishedDate {
            timestamp
          }
        }
        _type {
          description
          identifier
        }
        workflow_stage
        ${fields?.map(f => _generateFieldValueQuery(f.identifier, f.fieldTypeIdentifier))}
      }
    }
  }`
}


/**
 * Some field types require to add subselections. This helper adds them or otherwise
 * just returns the field identifier in camelCase.
 *
 * @param {string} identifier The internal name of the field
 * @param {string} fieldTypeIdentifier Type of the field like ezimage, eztext
 * @returns {string} A snipped to be placed inside a GraphQL query
 */
const _generateFieldValueQuery = (identifier, fieldTypeIdentifier) => {
  switch (fieldTypeIdentifier) {
    // TODO: At the moment, we display only the content type as category instead of sckenhancedselection value
    case 'sckenhancedselection':
      return ''
    case 'ezdatetime':
      return `${camelCase(identifier)} {
      timestamp
    }\n`
    // ezimage field also requests a list of variations which can be extended using the query
    // name is used to distinguish between variations in the result
    case 'ezimage':
      return `${camelCase(identifier)} {
      height
      uri
      width
      variations (identifier: [${IMAGE_VARIATION_APP_REPORT_DETAIL}]) {
        height
        name
        uri
        width
      }
    }\n`
    case 'ezgmaplocation':
      return `${camelCase(identifier)} {
      latitude
      longitude
      text
    }\n`
    default:
      return `${camelCase(identifier)}\n`
  }
}

/**
 * Fetches a list of content types contained in a content type group by the group's numerical id
 * @param  {integer} groupId The id of the content type group
 * @return {string}          A GraphQL query for fetching content types
 */
export const queryContentTypesByGroup = groupId => `{
  _repository {
    contentTypes (groupId: "${groupId}") {
      identifier,
      name,
      fieldDefinitions {
        identifier,
        fieldTypeIdentifier,
      }
    }
  }
}`

/**
 *
 * @param   {string} contentTypeGroup            The name of the content type group where the content type is defined in
 * @param   {array}  contentTypeFieldDefinitions An array containgin objects with contentTypeIdentifiers and related fieldSignatures
 * @returns {string}                             A GraphQL query that requests specific details to each field inside a list of content types
 */
export const queryMultipleFieldDefinitions = (contentTypeGroup, contentTypeFieldDefinitions) => {
  return `{
    ${camelCase(contentTypeGroup)} {
      _types {
        ${contentTypeFieldDefinitions.map(({ identifier, fieldDefinitions }) => _generateFieldDefinitionQuery(identifier, fieldDefinitions))}
      }
    }
  }`
}

/**
 * Query all Reports for poi data (location and workflow stage)
 * @param {array} contentTypeList List of content types that should be queried for user's content
 * @returns {object} location and workflow stage of reports
 */
export const queryReportsPoiData = (contentTypeList) => {
  // XXX per default only 10 edges are fetched, so use 9999 as a workaround to fetch all
  const _generateContentQuery = (contentTypeIdentifier) => `

    ${buildPlural(contentTypeIdentifier)} (first: 9999, query: { GeoHash: $geoHashes, ExcludeHidden: true }) {
      edges {
        node {
          _info {
            id
          }
          _type {
            identifier
          }
          location {
            longitude,
            latitude,
          }
          workflow_stage
        }
      }
    }
  `
  return `query queryReportsPoiData ($geoHashes: [String]) {
    ${camelCase(PUBLIC_REPORT_TYPES_GROUP_NAME)} {
      ${PREVENT_EMPTY_GRAPHQL_QUERY_SNIPPET}
      ${contentTypeList?.map(c => _generateContentQuery(c))}
    }
  }`
}


/**
 * GraphQL query for requesting all fields of a given content type (by variable).
 */
export const contentTypeFieldsQuery = `\
query ContentTypeFields ($contentTypeIdentifier: String!) {
  _repository {
    contentType (identifier: $contentTypeIdentifier) {
      fieldDefinitions {
        fieldTypeIdentifier
        identifier
        name
        position
      }
    }
  }
}`

const _generateFieldDefinitionQuery = (contentTypeIdentifier, fieldSignatures) => {
  return `
    ${camelCase(contentTypeIdentifier)} {
      _info {
        identifier,
        description,
      }
      ${fieldSignatures.map(({ identifier, fieldTypeIdentifier }) => _generateFieldTypeQuery(identifier, fieldTypeIdentifier))}
    }`
}


// This snippet is used to prevent invalid GraphQL syntax when executing the gql function inside Apollo hooks.
// The invalid syntax occurs because of an empty `contentTypeList` which causes the error `Expected name, found "}"`.
// This snippet prevents this error, by acting as a filler, even if the content type list is empty.
const PREVENT_EMPTY_GRAPHQL_QUERY_SNIPPET = `
  _info {
    id
  }`


/**
 * Generates a query for a field inside a content type
 * @param  {string} identifier          The identifier of this field in the content type
 * @param  {string} fieldTypeIdentifier Defines the type of the field. Used to determine the information to fetch
 * @return {string}                     A fragment to be inserted into a GraphQL query
 */
const _generateFieldTypeQuery = (identifier, fieldTypeIdentifier) => {
  if (!BLACKLIST_FIELD_TYPE_QUERY_IDENTIFIERS.includes(identifier)) {
    // identifier has to be converted to camel case in order to work with Ibexa GraphQL schema
    return `
      ${camelCase(identifier)} {
        description,
        fieldTypeIdentifier,
        id,
        identifier,
        isRequired,
        name,
        position,
        ${_getSpecialFieldAttributes(fieldTypeIdentifier)}
      }`
  } else {
    return ''
  }
}


/**
 * Returns fields which are specific to certain field types to be inserted into a GraphQL query
 * @param  {string} fieldType The identifier of the fieldType
 * @return {string}           A string fragment to be inserted into the GraphQL query
 */
const _getSpecialFieldAttributes = fieldType => {
  return (fieldType === 'sckenhancedselection')
    ? `settings {
        isMultiple,
        options {
          identifier,
          name,
          priority,
        }
      }`
    : ''
}
