import React,{ useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as ApolloProviderClient,
} from '@apollo/client';

const GRAPHQL_PATH = 'graphql';

/** Initializes ApolloClient to provide graphql query handling */
const ApolloProvider = ({children}) => {

  // initalize Apollo Client
  const client = useMemo(() => (
    new ApolloClient({
      uri: GRAPHQL_PATH,
      cache: new InMemoryCache(),
    })
  ), [GRAPHQL_PATH]);

  return (
    <ApolloProviderClient client={client}>
      {children}
    </ApolloProviderClient>
  )
}

ApolloProvider.propTypes = {
  children: PropTypes.node,
}

export default ApolloProvider
